
.step-content {
    background: #fff;
    padding: 20px 30px;
    height: calc(100vh - 226px);
}
.step-content-header {
    font-weight: 500;
    .big {
        font-size: 28px;
    }
}
.demo-ruleForm {
    margin: 50px 0 0 40px;
    ::v-deep {
        .el-form-item__label {
            color: #333;
            font-size: 18px;
        }
        .el-form-item__content {
            font-size: 18px;
        }
    }
}
.prompt-icon {
    color: #D7D7D7;
    cursor: pointer;
    margin-right: 20px;
}
.prompt-text {
    margin-left: 30px;
    color: #FD4446;
    font-size: 18px;
}

::v-deep .radioGroup-red .el-radio .el-radio__input.is-checked + .el-radio__label{
    color: black;
}
::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner{
    box-shadow: none;
}
.isclick{
    color: #1E63F1
}
