
.custom-budget-content {
    padding: 40px 60px;
    height: 100%!important;
    .title {
        // font-size: 24px;
        // font-weight: bold;
        // margin-bottom: 40px;
        color: #333;
        font-size: 24px;
        padding-bottom: 20px;
    }
    & > .item {
        color: #989898;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .operate-box {
        .btn-red {
            position: absolute;
            right: 60px;
            bottom: 100px;
        }
    }
}
.table-time {
    margin-top: 30px;
    margin-bottom: 20px;
    .line {
        display: flex;
        border-top: 1px solid #EBEEF5;
        &.header {
            background: #F8F8F8;
            .item {
                height: 60px;
                line-height: 60px;
                text-align: center;
                color: #14141C;
                font-size: 14px;
                font-weight: 500;
            }
        }
        &:last-child {
            border-bottom: 1px solid #EBEEF5;
        }
        &.line-body {
            .item {
                display: flex;
                padding: 12px 10px;
            }
        }
    }
    .line-left {
        width: 120px;
        line-height: 60px;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        text-align: center;
    }
    .line-right {
        width: 1%;
        flex: 1;
        display: flex;
        .item {
            width: 1%;
            flex: 1;
            border-right: 1px solid #EBEEF5;
            .timer {
                cursor: pointer;
                width: 1%;
                flex: 1;
                height: 36px;
                background: #fff;
                border: 1px solid #eee;
                position: relative;
                .timer-hover {
                    display: none;
                    position: absolute;
                    top: -84px;
                    left: -120px;
                    background: #fff;
                    z-index: 1;
                    padding: 10px 10px 10px 20px;
                    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    border: 1px solid #DFDFDF;
                    width: 200px;
                    font-size: 18px;
                    box-sizing: border-box;
                }
                &.current {
                    background: #FFECC8;
                    border-color: #fff;
                    border-top-color: #FFECC8;
                    border-bottom-color: #FFECC8;
                }
                &:hover {
                    .timer-hover {
                        display: block;
                    }
                }
            }
            .timer + .timer {
                border-left: none;
            }
        }
    }
}
.custom-time-wrapper {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 9999;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #DFDFDF;
    padding: 20px 20px 30px;
    width: 246px;
    box-sizing: border-box;
    .title {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .bottom-box {
        margin-left: 30px;
    }
}
.radioGroup-red {
    &.one-line {
        .el-radio {
            display: block;
            margin-bottom: 22px;
        }
        .number-input {
            width: 136px;
            margin-left: 30px;
            margin-bottom: 22px
        }
    }
}
.isclick{
    color: #1E63F1
}
