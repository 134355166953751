
.custom-budget-content {
    padding: 40px 60px;
    .title {
        // font-size: 24px;
        // font-weight: bold;
        // margin-bottom: 40px;
        color: #333;
        font-size: 24px;
        padding-bottom: 20px;
    }
    .item {
        color: #989898;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .operate-box {
        color: #989898;
        font-size: 18px;
        margin-bottom: 20px;
    }
}
